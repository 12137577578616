import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { Parallax } from 'react-scroll-parallax';
const kiegeszitok = () => (
  <Layout>
    <SEO title="Kiegészitők" description="Egyénre szabott optika Zugló szívében"  />
    <div className="sitewrap xl:max-w-2xl justify-center  mr-auto ml-auto      mt-10 ">
    <div className="  flex    mr-auto ml-auto   mt-20">
    <div className="F12F7B p-4      mb-10 mt-10 ">
    



<div className="left2 mt-20 "><h3 className="maintext2 left2 ">Boltunkban az alábbi kiegészítőket találhatja meg:</h3>


<ul className="maintext2 mt-2 left2 text-left"> 
<li>kemény és puha (zsák) tokok</li>
<li>tisztító kendők és sprék</li>
<li>szemüvegtartó láncok és zsinórok</li>
<li>szárfék</li>
<li>sport pánt</li>
<li>kültéri és szobai hőmérők</li>
<li>nagyítók</li>
<li>iránytűk</li>
</ul>
<h1 className="maintext2 font-bold mt-10  text-left text-justify"> 
</h1> 








</div></div>

    </div></div>
  </Layout>
)

export default kiegeszitok
